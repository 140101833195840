<template>
  <div data-app>
    <v-card>
      <v-card-title>
        {{ cardTitle }}
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="supplierTypeCollection"
        :search="search"
        :sort-by="['name']"
        @click:row="handleClickItem"
        :loading="loadingTable"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <SupplierTypeModalForm
              :modalData="modalData"
              :permissions="permissions"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
              @new="handleNew"
            ></SupplierTypeModalForm>
            <v-spacer></v-spacer>
            <DeleteModalDialog
              :dialogDelete="dialogDelete"
              @closeDelete="handleCloseDelete"
              @deleteItemConfirm="handleDeleteItemConfirm"
            >
            </DeleteModalDialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import SupplierTypeModalForm, {
  initialFormData,
} from "./SupplierTypeModalForm";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";

import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";

export const ENDPOINT = "contact/supplierType/";

export const PERMISSION_TO = "contacts.supplierType.";

export default {
  name: "supplierTypes",
  components: { SupplierTypeModalForm, DeleteModalDialog },
  mixins: [listModelsMixins],
  data() {
    return {
      routeName: "supplierTypes",
      routePath: "/contacts/supplierTypes/",
      permissionTo: PERMISSION_TO,

      cardTitle: "Supplier Type",
      search: "",
      permissions: JSON.parse(localStorage.permissions),

      headers: [
        {
          text: "Supplier Type",
          value: "name",
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        editedId: null,
      },
      editedItem: Object.assign({}, initialFormData()),

      dialogDelete: false,
      loadingTable: true,
      endPoint: ENDPOINT,
    };
  },

  computed: {
    ...mapGetters(["supplierTypeCollection"]),
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.$nextTick(() => {
            this.editItem(id);
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapActions(["fetchSupplierType"]),
    fetchModel() {
      return this.fetchSupplierType();
    },
  },

  mounted() {
    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>
